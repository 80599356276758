import React, { Fragment, useState, useEffect } from "react";
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import FossilBackImg from "./fossilBack";
import SpecieslBackImg from "./speciesBack";
import './dinosaurTimeline.css';
import FsLightbox from 'fslightbox-react';
import { createPortal } from "react-dom";
import { Item } from '../../items/item';

import Slider from "react-slick";
import { backgroundImages } from "polished";
// import { start } from "@craco/craco/lib/cra";
import Modal from "./modal";

import { exportedBackgroundColor, exportedStartTime, exportedEndTime, exportedTextColor } from "../panelTimeline/view";


function Portal({ children }) {
    const [el] = useState(document.createElement('div'));
    useEffect(() => {
        let mount = document.getElementById("portal-root");
        mount.appendChild(el);
        return () => mount.removeChild(el);
    }, [el]);
    return createPortal(children, el);
}

export function TimelineItem(props) {
    const item = props.item;
    const subItems = item.items || [];
    const cmsOption = props.cmsOption || {};

    const [toggler, setToggler] = React.useState(false);
    const [popupIndex, setPopupIndex] = React.useState(0);

    const [isSliderEle, setIsSliderEle] = useState([]);
    const [isSliderOpen, setIsSliderOpen] = useState(false);


    const stateItems = ['WA', 'SA', 'NT', 'QLD', 'NSW', 'ACT', 'VIC', 'TAS'];
    // const [stateItemData, setStateItemData] = useState('');

    const yearOfAge = cmsUtils.payload(item, "YearOfAge");
    const stateAus = cmsUtils.payload(item, "StateAus");
    const yearOfEon = cmsUtils.payload(item, "YearOfEon");
    const yearOfEra = cmsUtils.payload(item, "YearOfEra");
    const yearOfPeriod = cmsUtils.payload(item, "YearOfPeriod");
    const descriptionOfEra = cmsUtils.payload(item, "DescriptionOfEra");
    const briefOfEra = cmsUtils.payload(item, "BriefOfEra");
    const titleOfEra = cmsUtils.payload(item, "TitleOfEra");
    const titleOfFossil = cmsUtils.payload(item, "TitleOfFossil");
    const briefDesOfFossil = cmsUtils.payload(item, "BriefDesOfFossil");
    const mainDesOfFossil = cmsUtils.payload(item, "MainDesOfFossil");
    const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl"));
    const bgUrl2 = utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl2"));

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [bgImgUrl, setBgImgUrl] = useState("");

    // 打开 Modal
    const openModal = (imageUrl) => {
        setBgImgUrl(imageUrl); // 设置背景图片 URL
        setIsModalOpen(true); // 打开 Modal
    };

    // 关闭 Modal
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const subItemsElts = subItems.map((subItem, index) =>
        <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
    );

    const CustomPrevArrow = (props) => {
        const { className, onClick } = props;
        return (
            <button
                className={`slick-prev-cus2 ${className}`}
                onClick={onClick}
            >
                {'<'}
            </button>
        );
    };

    const CustomNextArrow = (props) => {
        const { className, onClick } = props;
        return (
            <button
                className={`slick-next-cus2 ${className}`}
                onClick={onClick}
            >
                {'>'}
            </button>
        );
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplaySpeed: 3000,
        fade: false,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };

    // console.log("subItemsElts:", subItemsElts);
    // console.log("subItemsElts[0].props.item.payloads[0].value: ", subItemsElts[0].props.item.payloads[0].value);

    const FOSSIL_IMG_DATA = [];
    const FOSSIL_IMG_URL = subItemsElts[0].props.item.payloads[0].value.split('|');

    if (FOSSIL_IMG_URL.length > 0) {
        FOSSIL_IMG_URL.forEach(e => {
            FOSSIL_IMG_DATA.push(e);
        })
    }

    const FOSSIL_CAPTION = [];
    const FOSSIL_DUMMY = subItemsElts[0].props.item.payloads[1].value.split(',');

    if (FOSSIL_DUMMY.length > 0) {
        FOSSIL_DUMMY.forEach(e => {
            FOSSIL_CAPTION.push(e);
        })
    }


    console.log("FOSSIL_IMG_DATA: ", FOSSIL_IMG_DATA);

    // const openPopupImage = (index) => {
    //     setPopupIndex(index);
    //     setToggler(!toggler);
    // }

    const closeSlider = () => {
        setIsSliderOpen(false); // Close slider
    };

    const openPopupImage = (index) => {

        setIsSliderEle(
            FOSSIL_IMG_DATA.length > 0 ? (
                <div className="slider-container">
                    <button className="close-button" onClick={() => closeSlider(false)}>X</button>
                    <Slider {...settings} initialSlide={index}>
                        {FOSSIL_IMG_DATA.map((imgurl, idx) => (
                            <div key={idx} className="slider-item">
                                <img src={imgurl} alt={FOSSIL_CAPTION[idx]} />
                                <p style={{ color: "#FFFFFF" }}>{FOSSIL_CAPTION[idx]}</p>
                            </div>
                        ))}
                    </Slider>
                </div>
            ) : (
                <div>No Fossil Img!</div>
            )
        );

        setIsSliderOpen(true);
    };

    const openSpieceImg = () => {

    }

    // console.log("exportedStartTime", exportedStartTime);
    // console.log("exportedEndTime", exportedEndTime);

    return (

        <div>
            {isSliderOpen && isSliderEle && (
                <Portal>
                    {isSliderEle}
                </Portal>
            )}

            <div className="timeline-subitem-main-container">

                {/* <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}> */}
                <div className="states">
                    <ul className="states-ul">
                        {stateItems.map((stateItem, index) => (
                            <li key={index} style={{ backgroundColor: stateItem === stateAus ? "#653330" : "#515151" }}>
                                {stateItem}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="species-main-content-container">
                    <div className="fossil-content" style={{ backgroundColor: exportedBackgroundColor, color: exportedTextColor }}>
                        {/* <FossilBackImg /> */}
                        <div className="fossil-content-container">
                            {/* <div><img src={bgUrl2} style={{ maxWidth: "100%" }} /></div> */}
                            <div className="fossil-img-popup">
                                <img src={bgUrl2} />
                            </div>
                            <div>
                                <div className="fossil-title"><strong>{titleOfFossil}</strong></div>
                                {/* <div style={{ fontWeight: "400", margin: "5px 0" }}>{briefDesOfFossil}</div> */}
                                <div className="fossil-text">{mainDesOfFossil}</div>
                            </div>

                        </div>

                    </div>
                    <div className="species-content-container">
                        {/* <SpecieslBackImg color={"#FFFFFF"} /> */}
                        <div className="species-content">
                            <div className="brief-intro"><strong>{yearOfEra}</strong> - {yearOfAge} millions years ago</div>
                            <h2 className="species-title">{titleOfEra}</h2>
                            <div className="species-subtitle">{briefOfEra}</div>
                            <div className="species-text">{descriptionOfEra}</div>
                        </div>

                    </div>
                    <div className="hero-fossil-img-container">
                        <div className="species-img"><img src={bgUrl} onClick={() => openModal(bgUrl)} />
                            {isModalOpen && (
                                <Modal isModalOpen={isModalOpen} onClose={closeModal}>
                                    <div className="modal-img">
                                        <img src={bgUrl} />
                                    </div>
                                </Modal>
                            )}
                        </div>
                        <div className="fossil-right-content" style={{ flexGrow: 1 }}>
                            <div className="fossil-photo-gallery">
                                {FOSSIL_IMG_DATA.map((FOSSIL_IMG, index) => (
                                    <div key={index} className="fossil-img">
                                        <img
                                            src={FOSSIL_IMG} alt={FOSSIL_CAPTION[index]}
                                            onClick={() => openPopupImage(index)}
                                        />
                                    </div>
                                ))}
                            </div>
                            <div className="fossil-photo-gallery-text">
                                <p>Click on an image to enlarge</p>
                            </div>
                        </div>

                    </div>
                </div>


                {/* </div> */}
                {/* <div className="timeline-bar" style={{ backgroundColor: exportedBackgroundColor }}>
                            <h3>timeline bar</h3>
                        </div> */}

            </div>

        </div>





    )
}
