import { includes } from "lodash";
import { position } from "polished";
import React, { useEffect, useRef } from "react";
import './timebar.css';
import { ColumnsIcon } from "lucide-react";
import { exportedTextColor } from "./view";

export const CreateTimeBar = ({ name, startTime, endTime, backgroundColor, yearData, onLineClick}) => {
    // console.log("Creating timeline with:", { name, startTime, endTime, backgroundColor, yearData });

    const TIMEBAR_EON = {
        fontWeight: '400',
        color: exportedTextColor
    }

    const timebarRef = useRef(null);

    useEffect(() => {
        const timebar = timebarRef.current;
        if (!timebar) return;

        const timebarWidth = timebar.offsetWidth;

        function getLinePosition(year) {
            const timeDuration = startTime - endTime;
            const relativePosition = (startTime - year) / timeDuration;
            return relativePosition * timebarWidth;
        }

        function createVerticalLine(year) {
            const line = document.createElement("div");
            line.className = "vertical-line";
            line.style.left = `${getLinePosition(year)}px`;

            line.addEventListener("click", (e) => {
                e.preventDefault();
                if(onLineClick) {
                    onLineClick(year);
                }
            });

            timebar.appendChild(line);
        }

        // 清除已有的垂直线，避免重复渲染
        timebar.querySelectorAll(".vertical-line").forEach(line => line.remove());

        // 创建新的垂直线
        yearData.forEach((year) => createVerticalLine(year));

    }, [startTime, endTime, backgroundColor, yearData]);

    return (
        <React.Fragment>

            <div ref={timebarRef} style={{ backgroundColor: backgroundColor }} className="timebar-style" id="timebar">
                <div className="start-time">{startTime}Mya</div>
                <div style={TIMEBAR_EON}>{name}</div>
                <div className="end-time">{endTime}Mya</div>
            </div>
            <div>

            </div>
        </React.Fragment>

    )
}