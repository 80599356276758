/** @jsx jsx */
import { jsx } from '@emotion/core'
import utils from '../../utils';
import { SiteLink } from '../../components';

import { TileList } from '../tileList/tileList'
import { getTileListStyle } from '../tileList/tileListStyle';
import { settings } from './config';

import { getImageTileStyle } from './galleryStyle';

export function GalleryTiles({ images, openPopupImage, useSlider = false }) {
  if (images.length === 0) return null;
  const tiles = images.map((img, index) => <ImageTile key={index} image={img} useSlider={useSlider} openPopupImage={openPopupImage} />)
  return (
    <div
      css={{
        maxWidth:'960px',
        marginInline:'auto',
      }}
    >
      <TileList tiles={tiles} style={getTileListStyle(settings)} useSlider={useSlider} />
    </div>
  )


}

function ImageTile({ image, useSlider, disableLink, openPopupImage }) {
  const s = getImageTileStyle(settings);
  //const imageUrl = utils.site.resourcePath(image.imageUrl);
  const thumbnailUrl = image.thumbnailUrl || image.imageUrl;
  const tileLink = image.imageUrl;

  return <SiteLink css={[s.tile, useSlider && s.tileSlider]} to={disableLink ? '' : tileLink} onClick={(e) => openPopupImage(e, image)}>
    <div css={s.tileBg} className="tileBg" style={{ backgroundImage: utils.css.bgUrlStyle(thumbnailUrl) }}></div>
  </SiteLink>
}
