import React, { Fragment, useEffect, useState, useRef } from "react";
import { Item } from '../../items/item';
import cmsUtils from "../../utils/cmsUtils";
import utils from "../../../utils";
import { Split } from "lucide-react";
import { forEach } from "lodash";
import { createPortal } from "react-dom";
import Slider from "react-slick";
import { DINOSAUR_TIMELINE_DATA, findTimelineData } from './timelineData';
import { CreateTimeBar } from "./CreateTimeBar";
import timelinePanel from './timelineStyle';
import './timelinePanel.css';
import { position } from "polished";
import { bg } from "date-fns/locale";

export let exportedBackgroundColor;
export let exportedTextColor;
export let exportedStartTime;
export let exportedEndTime;
// import DinosaurTimelineItem from "../timelineItem/dinosaurTimeline";

function Portal({ children }) {
  const [el] = useState(document.createElement('div'));
  useEffect(() => {
    let mount = document.getElementById("portal-root");
    mount.appendChild(el);
    return () => mount.removeChild(el);
  }, [el]);
  return createPortal(children, el);
}


export function PanelTimeline(props) {
  const item = props.item;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, "ImageUrl"));
  const defaultTitle = utils.site.resourcePath(cmsUtils.payload(item, "DefaultTitle"));
  const defaultBody = utils.site.resourcePath(cmsUtils.payload(item, "DefaultBody"));

  // const bgColor = cmsUtils.payload(item, "BgColor") || "#515151";

  const useSlider = cmsUtils.payload(item, 'UseSlider') === 'slider';
  const [timeBarData, setTimeBarData] = useState();

  const dinoSubitems = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );

  // console.log("dinoSubitems:", dinoSubitems[0].props.item.payloads[7].key, dinoSubitems[0].props.item.payloads[7].value);

  // console.log("dinoSubitems: ", dinoSubitems);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentContent, setCurrentContent] = useState([]);

  const [isButtonClicked, setIsButtonClicked] = useState(null);

  const [isTimebarOpen, setIsTimebarOpen] = useState(false);

  const scrollbarRef = useRef(null);
  const [scrollAmount, setScrollAmount] = useState(200);

  const [selectedYear, setSelectedYear] = useState(null);
  const [matchedYearData, setMatchedYearData] = useState([]);

  const sliderRef = useRef(null);

  const scrollLeft = () => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollLeft = scrollbarRef.current.scrollWidth;
    }
  })

  const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button
        className={`slick-prev-cus ${className}`}
        onClick={onClick}
      >
        {'<'}
      </button>
    );
  };

  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <button
        className={`slick-next-cus ${className}`}
        onClick={onClick}
      >
        {'>'}
      </button>
    );
  }

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 3000,
    fade: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  // const [activeButton, setActiveButton] = useState(null);

  const handleEraClick = (e) => {



    const buttonText = e.target.innerText;
    const btnBackgroundColor = e.currentTarget.style.backgroundColor;
    const btnTextColor = e.currentTarget.style.color || 'black';

    // const matchedItems = [];
    const newMatchedYearData = [];
    setMatchedYearData([]);

    // setActiveButton(buttonText);

    exportedBackgroundColor = btnBackgroundColor;
    exportedTextColor = btnTextColor

    setIsButtonClicked(buttonText);

    const result = findTimelineData(buttonText, DINOSAUR_TIMELINE_DATA);

    // console.log("Time bar:", result, "Start time:", result.startTime, "End time: ", result.endTime);
    // console.log("Background-Color", btnBackgroundColor);
    // console.log("btnTextColor:", btnTextColor)

    dinoSubitems.forEach(dinoSubitem => {
      const payloads = dinoSubitem.props.item.payloads || [];
      const eon = payloads[15]?.value;
      const era = payloads[16]?.value;
      const period = payloads[17]?.value;

      // 检查按钮文本是否与 eon、era 或 period 匹配
      if (eon === buttonText || era === buttonText || period === buttonText) {
        // matchedItems.push(dinoSubitem);
        newMatchedYearData.push({
          year: payloads[8].value,
          item: dinoSubitem,
        })
      }

    });

    setMatchedYearData(newMatchedYearData);

    // console.log("Matched Items for", buttonText, ":", matchedItems);

    console.log("matchedYearData: ", matchedYearData);

    setTimeBarData({
      name: result.name,
      startTime: result.startTime,
      endTime: result.endTime,
      backgroundColor: btnBackgroundColor,
      yearData: newMatchedYearData.map(data => data.year),
    });

    // setCurrentContent(
    //   matchedItems.length ? (
    //     <Slider {...settings}>
    //       {matchedItems}
    //     </Slider> ) : (
    //     <div className="no-data-container">
    //       <h3 style={{ color: btnBackgroundColor }}>No Dinosaur's Data!</h3>
    //     </div>
    // ))

    setCurrentContent(
      newMatchedYearData.length ? (
        <Slider ref={sliderRef} {...settings}>
          {newMatchedYearData.map((e, index) => (
            <div key={index}>
              {e.item}
            </div>
          ))}
        </Slider>) : (
        <div className="no-data-container">
          <h3 style={{ color: exportedBackgroundColor }}>No Dinosaur's Data!</h3>
        </div>
      ))

    setIsTimebarOpen(true);

  };

  const handleOnLineClick = (year) => {
    setSelectedYear(year);

    const index = matchedYearData.findIndex(e => e.year === year);
    if (index !== -1) {
      sliderRef.current.slickGoTo(index);
    }
  };

  // console.log("PanelTimeline", item);

  // console.log("image url:", bgUrl, typeof bgUrl);

  return (
    <div className="modal-bg">
      <div className="timeline-main-content">

        {isButtonClicked ? (
          <div className="current-content">
            {currentContent}
          </div>
        ) : (
          <div className="timeline-context-container">
            <h2>
              {defaultTitle}
            </h2>
            <p>
              {defaultBody}
            </p>
            <img src="/sites/ageofdinosaurs/media/Timeline/animals-home-page.png"></img>
          </div>
        )}

        <div className="timebar-container">
          {timeBarData ? (
            <CreateTimeBar
              name={timeBarData.name}
              startTime={timeBarData.startTime}
              endTime={timeBarData.endTime}
              backgroundColor={timeBarData.backgroundColor}
              yearData={timeBarData.yearData}
              onLineClick={handleOnLineClick}
            />
          ) : (
            <div style={{ display: 'none' }}>No data!</div>
          )}
        </div>


        <div className="table-container">

          <button
            className="scroll-button left-arrow"
            onClick={scrollLeft}
            aria-label="Scroll Left"
          >
            {'<'}
          </button>
          {/* 第一部分：三行一列的表格 */}
          <div className="period">
            <div className="period-cell">Period</div>
            <div className="period-cell">Era</div>
            <div className="period-cell">Eon</div>
          </div>

          {/* 第二部分：复杂表格 */}



          <div
            ref={scrollbarRef}
            className="second-table"
          >
            <div className="table-hadean">
              <div style={{ width: "30px" }}>
                <button style={{ backgroundColor: isButtonClicked === 'Hadean' ? "#9a9a9a" : "#B6158E" }} onClick={handleEraClick}>Hadean</button>
              </div>
            </div>
            {/* 第一行：4列 */}
            <div className="table-archean">
              <div className="row-1">
                <div className="cell n" style={{ backgroundColor: "#9a9a9a" }}></div>
                <div className="cell n" style={{ backgroundColor: "#9a9a9a" }}></div>
                <div className="cell n" style={{ backgroundColor: "#9a9a9a" }}></div>
                <div className="cell n" style={{ backgroundColor: "#9a9a9a" }}></div>
              </div>
              <div className="row-2">
                <div className="cell n-group"><button style={{ backgroundColor: isButtonClicked === 'Eoarchean' ? "#9a9a9a" : "#DA008D" }} disabled={isButtonClicked === 'Eoarchean'} onClick={handleEraClick}>Eoarchean</button></div>
                <div className="cell n-group"><button style={{ backgroundColor: isButtonClicked === 'Paleoarchean' ? "#9a9a9a" : "#F366A7" }} disabled={isButtonClicked === 'Paleoarchean'} onClick={handleEraClick}>Paleoarchean</button></div>
                <div className="cell n-group"><button style={{ backgroundColor: isButtonClicked === 'Mesoarchean' ? "#9a9a9a" : "#F585AF" }} disabled={isButtonClicked === 'Mesoarchean'} onClick={handleEraClick}>Mesoarchean</button></div>
                <div className="cell n-group"><button style={{ backgroundColor: isButtonClicked === 'Neoarchean' ? "#9a9a9a" : "#F9ACC4" }} disabled={isButtonClicked === 'Neoarchean'} onClick={handleEraClick}>Neoarchean</button></div>
              </div>
              <div className="row-3">
                <div className="cell cell-1"><button style={{ backgroundColor: isButtonClicked === 'Archean' ? "#9a9a9a" : "#EF008D" }} disabled={isButtonClicked === 'Archean'} onClick={handleEraClick}>Archean</button></div>
              </div>
            </div>

            <div className="table-proterozoic">
              <div className="row-1">
                <div className="cell cell-a"><button style={{ backgroundColor: isButtonClicked === 'Siderian' ? "#9a9a9a" : "#F5718D" }} disabled={isButtonClicked === 'Siderian'} onClick={handleEraClick}>Siderian</button></div>
                <div className="cell cell-a"><button style={{ backgroundColor: isButtonClicked === 'Rhyacian' ? "#9a9a9a" : "#F57B97" }} disabled={isButtonClicked === 'Rhyacian'} onClick={handleEraClick}>Rhyacian</button></div>
                <div className="cell cell-a"><button style={{ backgroundColor: isButtonClicked === 'Orosirian' ? "#9a9a9a" : "#F685A2" }} disabled={isButtonClicked === 'Orosirian'} onClick={handleEraClick}>Orosirian</button></div>
                <div className="cell cell-a"><button style={{ backgroundColor: isButtonClicked === 'Statherian' ? "#9a9a9a" : "#F68FAD" }} disabled={isButtonClicked === 'Statherian'} onClick={handleEraClick}>Statherian</button></div>
                <div className="cell cell-b"><button style={{ backgroundColor: isButtonClicked === 'Calymmian' ? "#9a9a9a" : "#FFC793" }} disabled={isButtonClicked === 'Calymmian'} onClick={handleEraClick}>Calymmian</button></div>
                <div className="cell cell-b"><button style={{ backgroundColor: isButtonClicked === 'Ectasian' ? "#9a9a9a" : "#FFD19F" }} disabled={isButtonClicked === 'Ectasian'} onClick={handleEraClick}>Ectasian</button></div>
                <div className="cell cell-b"><button style={{ backgroundColor: isButtonClicked === 'Stenian' ? "#9a9a9a" : "#FFDCAC" }} disabled={isButtonClicked === 'Stenian'} onClick={handleEraClick}>Stenian</button></div>
                <div className="cell cell-c"><button style={{ backgroundColor: isButtonClicked === 'Tonian' ? "#9a9a9a" : "#FFC571" }} disabled={isButtonClicked === 'Tonian'} onClick={handleEraClick}>Tonian</button></div>
                <div className="cell cell-c"><button style={{ backgroundColor: isButtonClicked === 'Cryogenian' ? "#9a9a9a" : "#FFCF7D" }} disabled={isButtonClicked === 'Cryogenian'} onClick={handleEraClick}>Cryogenian</button>
                </div>
                <div className="cell cell-c"><button style={{ backgroundColor: isButtonClicked === 'Ediacaran' ? "#9a9a9a" : "#FFD989" }} disabled={isButtonClicked === 'Ediacaran'} onClick={handleEraClick}>Ediacaran</button>
                </div>
              </div>
              <div className="row-2">
                <div className="cell a-group"><button style={{ backgroundColor: isButtonClicked === 'Paleoproterozoic' ? "#9a9a9a" : "#F46682" }} disabled={isButtonClicked === 'Paleoproterozoic'} onClick={handleEraClick}>Paleoproterozoic</button></div>
                <div className="cell b-group"><button style={{ backgroundColor: isButtonClicked === 'Mesoproterozoic' ? "#9a9a9a" : "#FEBC80" }} disabled={isButtonClicked === 'Mesoproterozoic'} onClick={handleEraClick}>Mesoproterozoic</button></div>
                <div className="cell c-group"><button style={{ backgroundColor: isButtonClicked === 'Neoproterozoic' ? "#9a9a9a" : "#FEBB66" }} disabled={isButtonClicked === 'Neoproterozoic'} onClick={handleEraClick}>Neoproterozoic</button></div>
              </div>
              <div className="row-3">
                <div className="cell cell-2"><button style={{ backgroundColor: isButtonClicked === 'Proterozoic' ? "#9a9a9a" : "#F35A7A" }} disabled={isButtonClicked === 'Proterozoic'} onClick={handleEraClick}>Proterozoic</button></div>
              </div>
            </div>

            <div className="table-phanerozoi">
              <div className="row-1">
                <div className="cell d"><button style={{ backgroundColor: isButtonClicked === 'Cambrian' ? "#9a9a9a" : "#8AAB78", color: "white" }} disabled={isButtonClicked === 'Cambrian'} onClick={handleEraClick}>Cambrian</button></div>
                <div className="cell d"><button style={{ backgroundColor: isButtonClicked === 'Ordovician' ? "#9a9a9a" : "#00A98E", color: "white" }} disabled={isButtonClicked === 'Ordovician'} onClick={handleEraClick}>Ordovician</button></div>
                <div className="cell d"><button style={{ backgroundColor: isButtonClicked === 'Silurian' ? "#9a9a9a" : "#B1DDC9" }} disabled={isButtonClicked === 'Silurian'} onClick={handleEraClick}>Silurian</button></div>
                <div className="cell d"><button style={{ backgroundColor: isButtonClicked === 'Devonian' ? "#9a9a9a" : "#D19D5C" }} disabled={isButtonClicked === 'Devonian'} onClick={handleEraClick}>Devonian</button> </div>
                <div className="cell d"><button style={{ backgroundColor: isButtonClicked === 'Carboniferous' ? "#9a9a9a" : "#64AEB2" }} disabled={isButtonClicked === 'Carboniferous'} onClick={handleEraClick}>Carboniferous</button></div>
                <div className="cell d"><button style={{ backgroundColor: isButtonClicked === 'Permian' ? "#9a9a9a" : "#E9654D" }} disabled={isButtonClicked === 'Permian'} onClick={handleEraClick}>Permian</button></div>
                <div className="cell e"><button style={{ backgroundColor: isButtonClicked === 'Triassic' ? "#9a9a9a" : "#8F51A1", color: "white" }} disabled={isButtonClicked === 'Triassic'} onClick={handleEraClick}>Triassic</button></div>
                <div className="cell e"><button style={{ backgroundColor: isButtonClicked === 'Jurassic' ? "#9a9a9a" : "#00B8E5", color: "white" }} disabled={isButtonClicked === 'Jurassic'} onClick={handleEraClick}>Jurassic</button></div>
                <div className="cell e"><button style={{ backgroundColor: isButtonClicked === 'Cretaceous' ? "#9a9a9a" : "#85C870" }} disabled={isButtonClicked === 'Cretaceous'} onClick={handleEraClick}>Cretaceous</button></div>
                <div className="cell f"><button style={{ backgroundColor: isButtonClicked === 'Paleogene' ? "#9a9a9a" : "#FCA972" }} disabled={isButtonClicked === 'Paleogene'} onClick={handleEraClick}>Paleogene</button></div>
                <div className="cell f"><button style={{ backgroundColor: isButtonClicked === 'Neogene' ? "#9a9a9a" : "#FFDF37" }} disabled={isButtonClicked === 'Neogene'} onClick={handleEraClick}>Neogene</button></div>
                <div className="cell f"><button style={{ backgroundColor: isButtonClicked === 'Quaternary' ? "#9a9a9a" : "#FFF89A" }} disabled={isButtonClicked === 'Quaternary'} onClick={handleEraClick}>Quaternary</button></div>
              </div>
              <div className="row-2">
                <div className="cell d-group"><button style={{ backgroundColor: isButtonClicked === 'Paleozoic' ? "#9a9a9a" : "#9DC1A6" }} disabled={isButtonClicked === 'Paleozoic'} onClick={handleEraClick}>Paleozoic</button></div>
                <div className="cell e-group"><button style={{ backgroundColor: isButtonClicked === 'Mesozoic' ? "#9a9a9a" : "#3FC6E8" }} disabled={isButtonClicked === 'Mesozoic'} onClick={handleEraClick}>Mesozoic</button></div>
                <div className="cell f-group"><button style={{ backgroundColor: isButtonClicked === 'Cenozoic' ? "#9a9a9a" : "#FBEF39" }} disabled={isButtonClicked === 'Cenozoic'} onClick={handleEraClick}>Cenozoic</button></div>
              </div>
              <div className="row-3">
                <div className="cell cell-3"><button style={{ backgroundColor: isButtonClicked === 'Phanerozoic' ? "#9a9a9a" : "#8DD7EB" }} disabled={isButtonClicked === 'Phanerozoic'} onClick={handleEraClick}>Phanerozoic</button></div>
              </div>
            </div>

          </div>
          <button
            className="scroll-button right-arrow"
            onClick={scrollRight}
            aria-label="Scroll Right"
          >
            {'>'}
          </button>

        </div>
        <div className="bottom-text">
          <p>Scroll slideways to choose an Era</p>
        </div>
      </div>

    </div>
  )
}