
import { borderRadius } from "polished";
import React from "react";

import ReactDom from 'react-dom';
import { MdWidthFull } from "react-icons/md";

const CLOSE_BUTTON= {
    position: 'fixed',
    top: 0,
    right: 0,
    // transform: 'translate(-50%, -50%)',
    width: '30px',
    height:'30px',
    backgroundColor: 'rgba(255,255,255,0.7)',
    padding: '10px',
    margin:'3%',
    zIndex: 2000,
    display:'flex',
    justifyContent:'center',
    alignItems: 'center',
    borderRadius: '50%',
    fontWeight: '700',
}

const OVERLAY_STYLES = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.8)',
    zIndex: 1000,
}

export default function Modal({ isModalOpen, children, onClose }) {
    if (!isModalOpen) return null;
    return ReactDom.createPortal(
        <>
            <div style={OVERLAY_STYLES} />
            <div>
                <button style={CLOSE_BUTTON} onClick={onClose}>X</button>
                {children}
            </div>
        </>,
        document.getElementById('portal-root')
    )
}